<template>
  <CNavbar
      expandable="md"
      color="white"
  >
    <div class="container mt-4">
      <div class="d-flex justify-content-between align-items-center w-100">
        <router-link to="/pages/login" class="text-main_black">
          <div class="d-flex align-items-center">
            <img src="@/assets/icons/logo_btc_mono 1.svg" alt="">
            <span class="ml-2">Professional cryptocurrency<br/>
mining solutions</span>
          </div>
        </router-link>
        <div class="d-flex align-items-center">
          <div class="links">
          </div>
          <div class="social">
            <img src="@/assets/icons/ic_facebook.svg" alt="" class="mx-2">
            <img src="@/assets/icons/ic_insta.svg" alt="" class="mx-2">
          </div>
          <div>
            <a href="" class="mx-2 text-main_black">РУСС</a>
            <a href="" class="mx-2 text-main_black">ENG</a>
          </div>
        </div>
      </div>
    </div>
  </CNavbar>
</template>

<script>
export default {
  name: "AuthNavbar.vue"
}
</script>

<style scoped>

</style>