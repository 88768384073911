<template>
  <div>
    <AuthNavbar/>
    <div class="c-app flex-row ">
      <CContainer>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard class="p-4 border-0">
                <CCardBody>
                  <CForm>
                    <h1 class="text-main_black font-2xl font-weight-normal">Password recovery</h1>
                    <p class="text-gray">Enter your email. An email will be sent to your email to reset your password. The link is valid for no more than 30 minutes, one-time link.</p>
                    <div class="mt-lg-4">
                      <p class="d-flex flex-column mb-3">
                        <label for="email">Email</label>
                        <input type="email" id="email" v-model="email" :class="(error.message ? 'border-error_color' : '') + ' p-2 pl-3 bg-milk_mix border rounded-lg outline-none text-dark'"/>
                        <span class="text-error_color mt-1">{{ error.message ? error.message : "" }}</span>
                      </p>
                      <CButton @click="onLogin" :disabled="!inputsAreValid() || loading" color="submit" class="text-white p-2 mt-2 w-100 rounded-md" shape="square">SEND</CButton>
                    </div>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {Trans } from "../../plugins/Translation";
import AuthNavbar from '../base/AuthNavbar';

export default {
  name: 'Recovery',
  components: {AuthNavbar},
  data () {
    return {
      email: "",
      loading: false,
      error: {
        message: ""
      },
      langs: Trans.supportedLocales
    }
  },
  methods: {
    inputsAreValid: function() {
      return this.emailIsValid();
    },
    changeLanguage(locale){
      Trans.changeLocale(locale);
    },
    emailIsValid: function() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    },
    onLogin: function(){
      this.loading = true;
      axios({
        method: 'post',
        url: process.env.VUE_APP_API+'/api/v1/password/reset',
        data: {
          email: this.email
        },
      })
          .then(() => {
            this.$router.push('status/success');
          })
          .catch((res) => {
            this.loading = false;
            this.error.message = res.response.data.message;
          })
          .then(()=>{
            this.loading = false;
          });
    },
  }
}
</script>